import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import cuid2 from '@paralleldrive/cuid2';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faCopy } from '@fortawesome/free-solid-svg-icons'

function App() {

  const [cuid, setCuid] = useState(cuid2.createId())

  const [justCopied, setJustCopied] = useState(false)

  const copy = (cuid: string) => {
    navigator.clipboard.writeText(cuid)
    setJustCopied(true)
  }

  const generateNew = () => {
    const cuid = cuid2.createId()
    setCuid(cuid)
    return cuid
  }


  return (
    <div className="App container my-3">
      <h3>Your next <a href="https://github.com/paralleldrive/cuid2" target="_blank">Cuid2</a> is:</h3>

      <OverlayTrigger defaultShow={justCopied} trigger={["hover","focus"]} overlay={<Tooltip key={`${justCopied}`}>{justCopied ? "Copied!" : "Click to copy"}</Tooltip>} onExit={() => setJustCopied(false)}>
        <div className="fs-1 my-3" style={{ cursor: "pointer" }} onClick={() => copy(cuid)}>
          <code>{cuid} </code>
          <FontAwesomeIcon icon={faCopy} />
        </div>
      </OverlayTrigger>

      <Button onClick={generateNew} variant="secondary" className="my-1"><FontAwesomeIcon icon={faGear} />&nbsp;Generate</Button>{" "}
      <Button onClick={() => {copy(cuid); generateNew()}} className="my-1"><FontAwesomeIcon icon={faCopy} />&nbsp;Copy and generate</Button>{" "}
    </div>
  );
}

export default App;